/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - baskets or berries
 * @param {string} partNumber - SKU to be fetched
 */

const findProductPageByPartNumber = (brand, partNumber, pricingOptions = null) => (

    // very important for serializing. add space between bracket and query name + add typenames to query
    gql`query ProductPageQuery${!brand && !partNumber ? `($brand: String!, $partNumber: String! ${pricingOptions ? ', $pricingOptions: PricingOptions' : ''})` : ''}{ findProductPageByPartNumber(brand: ${!brand ? '$brand' : `"${brand}"`}, environment: "${GRAPHQL_ENV}", partNumber: ${!brand ? '$partNumber' : `"${partNumber}"`} ${pricingOptions ? ', pricingOptions: $pricingOptions' : ''}) {
            content
            defaultContent
            product {
                categories
                name
                isSmartgiftEnabled
                seo {
                    url
                    canonicalUrl
                }
                partNumber
                isPromoRestricted
                addonGroups{
                        name,
                        maxAllowedAddons,
                        addons{
                            brandId,
                            partNumber,
                            id,
                            legacyId,
                            name,
                            shortDescription,
                            image{
                                path,
                                name
                            },
                        prices{
                            currency,
                            type,
                            value
                        },
                        ageVerifyFlag
                    }
                }
                baseCode
                brand
                brandId
                parentCategories {
                    id
                }
                displayDeliveryCalendar
                shortDescription
                longDescription
                isPassportEligible
                productType
                legacyId
                isPersonalizable
                image {
                    name
                    path
                    altText
                    additionalImages
                    snipe
                    snipeImagePath
                    snipeProductEndDate
                    snipeCategoryEndDate
                    snipeProductStartDate
                    snipeCategoryStartDate
                    __typename
                }
                upsells {
                    id
                    name
                    image {
                        name
                        path
                        snipe
                        snipeImagePath
                        altText
                    }
                prices {
                    currency
                    type
                    value
                    }
                }
                productContents
                availability {
                    deliveryMessage
                    productDeliveryType
                   __typename
                }
                attributes {
                    id,
                    name,
                    values {
                        value
                        __typename
                    }
                    __typename
                }
                productContents
                club {
                    defaultSku {
                        partNumber
                        availableDate
                        month
                        partNumber
                        startDate
                        endDate
                        image{
                            name
                            path
                        }
                        components{
                            partNumber
                            name
                            month
                            longDescription
                            shortDescription
                            productContents
                            startDate
                            endDate
                            availableDate
                            frequency
                            image{
                                name
                                path
                            }
                        }
                        frequency
                        upsells{
                            partNumber
                            month
                            name
                            startDate
                            longDescription
                            shortDescription
                            productContents
                            endDate
                            frequency
                            upSellDisplayPrice
                            image{
                                name
                                path
                            }
                        }
                    }
                    allItemsList {
                        month
                        frequency
                        availableDate
                        partNumber
                        id
                        startDate
                        endDate
                        availableDate
                        image{
                            name
                            path
                            altText
                        }
                    }
                }
                recommendedMarketplaceProducts
            productSkus {
                    priceSheet
                    id
                    brandId
                    name
                    productType
                    rank
                    isInternational
                    countries
                    legacyId
                    paymentMethod
                    shortDescription
                    longDescription
                    sympathyValue
                    miraklShopId
                    miraklOfferId
                    marketplaceShopDetails {
                      shopId
                      banner
                      shopName
                      description
                      freeShipping
                      passportPromotion
                      logo
                      returnPolicy
                    }
                    attributes {
                        name,
                        values {
                            value
                            __typename
                        }
                        __typename
                    }
                    subscriptionsList{
                        type
                        duration
                        interval
                    }
                    subscriptions{
                        type
                        duration
                        interval
                    }
                    personalization {
                        personalizationDefDesign
                        personalizationTemplate
                        personalizationAllowed
                        indicator
                        lines {
                            lineNumber
                            maxChars
                        }
                    }
                    image {
                        name
                        path
                        additionalImages
                        productThumbnails {
                            name
                            description
                            position
                            path
                            mediaType
                            isPrimary
                        }
                    }
                    deliveryIndicator
                    availability{
                        published
                        displayEndDate
                        earliestDefaultShipDate
                        earliestShipDate
                        deliveryMessage
                        defaultDeliveryMethod
                    }
                    ageVerifyFlag
                    addOnFlag
                    variantAttributes
                    containerType
                    bouquetIncludes
                    occasion
                    categoryHierarchies {
                        categoryHierarchyTreeList {
                            code
                            label
                        }
                    }
                    minAge
                    prices {
                    type
                    value
                    __typename
                    }
                   cyo {
                        maxQuantity
                        cyoGroup {
                            name
                            type
                            numberofUnitsRequiredForComponent
                            minimumUnitsRequiredForComponent
                            maxFlavorsForComponent
                            rank
                            components {
                                id
                                legacyId
                                partNumber
                                name
                                longDescription
                                shortDescription
                                image {
                                    name
                                    path
                                }
                            }
                        }
                      }
                __typename
                }
            __typename
            }
            isPassportBundleItem
        __typename
        }
    }
`
);
export default findProductPageByPartNumber;
