/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CaPriceVariant = (data) => {
    if (data?.variants?.length > 0 && Object.keys(data?.variants?.[0]?.value)?.length > 0) {
        const variants = JSON.parse(data?.variants?.[0]?.value);
        // Filter the campaignResponses based on the campaignName
        const filteredCampaign = variants?.campaign?.campaignResponses?.filter((response) => response?.payload?.testType?.includes('ca_delivery'));
        // Extract the testType from the payload of the first filtered campaign
        const testType = filteredCampaign?.[0]?.payload?.userGroup;
        // Return JSX with true or false
        return testType?.toLowerCase() === 'test';
    }
    return false;
};

export default CaPriceVariant;
